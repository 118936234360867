






















import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import ChartBase from '@/components/charts/ChartBase.vue'
import { getPercent } from '@/utils/functions'

@Component({
  components: {
    ChartBase,
  },
})
export default class StatsCircleLesson extends Vue {
  @Prop({ required: true })
  private total!: number

  @Prop({ required: true })
  private completed!: number

  @Prop({ required: true })
  private checked!: number

  @Prop({ required: true })
  private wait!: number

  @Prop({ default: false })
  private hovered!: boolean

  private percents(total: number, completion: number) {
    return getPercent(total, completion)
  }
}
