








































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ChartMentor from '@/components/charts/ChartMentor.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import ManagerMentorsModule from '@/store/modules/manager/mentors'
import Tag from '@/components/_uikit/Tag.vue'
import UserCard from '@/components/cards/UserCard.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerUsersModule from '@/store/modules/manager/users'

@Component({
  components: {
    ChartMentor,
    LinkTabs,
    Tag,
    UserCard,
    UserSocialCard,
  },
})
export default class MentorsItem extends Mixins(NotifyMixin, PermissionsMixin) {
  private get mentorID () {
    return +this.$route.params.mentorID
  }

  private get breadcrumbs () {
    return [{ name: 'Наставники', path: '/manager/education/mentors' }]
  }

  private get mentor () {
    return ManagerMentorsModule.mentor
  }

  private get tabs () {
    const list = [{ name: 'Данные и права', to: { name: 'manager.education.mentors.item.information' } }]

    if (this.hasPermission(this.Permission.MENTOR_MASTER_VIEW)) {
      list.push({ name: 'Мастера', to: { name: 'manager.education.mentors.item.masters' } })
    }
    if (this.hasPermission(this.Permission.MENTOR_LESSONS_VIEW)) {
      list.push({ name: 'Уроки', to: { name: 'manager.education.mentors.item.lessons' } })
    }
    if (this.hasPermission(this.Permission.MENTOR_EXERCISES_VIEW)) {
      list.push({ name: 'Домашняя работа', to: { name: 'manager.education.mentors.item.exercises' } })
    }

    return list
  }

  private get isMentorRootRoute () {
    return this.$route.name === 'manager.education.mentors.item'
  }

  private get user() {
    return ManagerUsersModule.user
  }

  private mounted () {
    if (!this.mentor || this.isMentorRootRoute) {
      this.fetchMentor()
        .then(() => {
          this.$router.replace({
            name: 'manager.education.mentors.item.information',
            params: { mentorID: this.mentorID.toString() },
          })
        })
    }
  }

  private fetchMentor () {
    return ManagerMentorsModule.fetchMentor(this.mentorID)
      .then(this.fetchUserMainData)
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.mentors' })
      })
  }

  private fetchUserMainData () {
    if (this.mentor) {
      return ManagerUsersModule.fetchUser(this.mentor.uuid)
      // fix sentry issue
      // Navigation cancelled from "/manager/education/mentors/8154" to "/manager/education/mentors/8154/masters" with a new navigation.
      // https://sn.atwinta.online/organizations/atwinta/discover/elya-smith-frontend:b14a867cc8a64f1e826b8c19a0cb481b/?field=title&field=release&field=environment&field=user&field=timestamp&name=Error%3A+Navigation+cancelled+from+%22%2Fmanager%2Feducation%2Fexercises%2F6946%2F8fe73640-e320-47b4-8f21-9ce73e6cd332...&project=22&query=issue%3AELYA-SMITH-FRONTEND-D6T&sort=-timestamp&statsPeriod=30d&yAxis=count%28%29
        /*.then(() => {
          this.$router.replace({
            name: 'manager.education.mentors.item.masters',
            params: { mentorID: this.mentorID.toString() },
          })
        })*/
        .catch((error: any) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.education.masters' })
        })
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Наставник ${this.mentor ? `${this.mentor.name} ${this.mentor.surname}` : ''}`,
    }
  }
}
